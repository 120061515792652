//Use action
import { caseInsert, caseUpdate, caseQueryDepartment, caseQuery } from '../../../action/case/caseActions.js';
import { caseLogQuery } from '../../../action/case/caseLogActions.js';
import { store } from 'react-notifications-component';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Container, Row, Col, Form, Offcanvas, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList, faSave, faPlus, faPen, faTimes, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css";
import './business-box.css';
import { useParams, useHistory } from "react-router-dom";
import { FormattedNumber } from '../FormattedNumber';
import { ConfigSelection } from '../ConfigSelection';
import moment from 'moment';
import Select from 'react-select';

const defaultMessage = {
    title: "新增",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};

const completeMessage = {
    title: "新增",
    message: "新增完成",
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};

const defaultCase = {
    name: '',
    status: 1,
    level: 1,
    degree: 1,
    progress: 1,
    totalValue: 0,
    comment: '',
    estimatedDate: new Date(),
    assistingBy: null,
    customer: null,
    departmentID: null,
    equipment: null,
    preSaleEquip: null,
    custContactsID: null
}

const defaultParam = {
    changeReason: '',
    jobClass: 1,
    visitedDate: new Date()
}



export const BusinessBox = (props) => {
    const dispatch = useDispatch();
    const hist = useHistory();
    const { id } = useParams();
    const { degree, jobclass, level, progress, status, preSaleEquip } = useSelector(x => x.config);
    const { businessCase, customers, organization, currentUser, caseLogs } = useSelector(x => x);
    const [newCase, setNewCase] = useState(() => {
        if (id === '0') {
            return {
                ...defaultCase,
                assistingBy: currentUser.userID,
                departmentID: currentUser.departmentID,
                custContactsID: -1
            };
        } else {
            return { ...businessCase.case.find(e => e.caseId == id) };// eslint-disable-line
        }

    });

    let is_ed = false;
    let TitleBar = ['新增', faPlus];
    if (id === '0') {
        TitleBar[0] = '新增';
        TitleBar[1] = faPlus;
        is_ed = false;
    } else {
        TitleBar[0] = '編輯';
        TitleBar[1] = faPenSquare;
        is_ed = true;
    }

    const [param, setParam] = useState(defaultParam);


    const [loadOptions] = useState(preSaleEquip.map(item => {
        return { value: item.value, label: item.text }
    }));

    const [inputValue, setInputValue] = useState(() => {
        if (id === '0') {
            return null;
        } else {
            if (newCase.preSaleEquip !== null) {
                if (newCase.preSaleEquip.includes(',')) {
                    let preSaleEquip_arry = newCase.preSaleEquip.split(',');
                    let preSaleEquip_obj = [];
                    let isOtherItem = true;
                    preSaleEquip_arry.forEach((str) => {
                        loadOptions.forEach((item) => {
                            if (item.value === parseInt(str)) {
                                preSaleEquip_obj.push(item);
                                isOtherItem = false;
                            }
                        })
                        if (isOtherItem === true) {
                            preSaleEquip_obj.push({ value: 12, label: str });
                        }
                        return preSaleEquip_obj;
                    });

                    return preSaleEquip_obj;
                } else {
                    return { value: 12, label: newCase.preSaleEquip };
                }
            }
        }
    });

    const toggleAdd = () => {
        sessionStorage.setItem('newCase', JSON.stringify(newCase));
        sessionStorage.setItem('param', JSON.stringify(param));
        sessionStorage.setItem('inputValue', JSON.stringify(inputValue));

        hist.push(`/customerbox/0`);
    }

    const toggleEdit = () => {
        if (newCase.customer === "" || newCase.customer === null) {
            window.alert("請先選擇客戶");
            return;
        }
        sessionStorage.setItem('newCase', JSON.stringify(newCase));
        sessionStorage.setItem('param', JSON.stringify(param));
        sessionStorage.setItem('inputValue', JSON.stringify(inputValue));

        hist.push(`/customerbox/${newCase.customer}`);
    }
    const close = () => {
        hist.goBack();
    }


    const [validated, setValidated] = useState(false);
    const handleSubmit = async (e, data) => {
        const from = e.currentTarget;
        if (from.checkValidity() === false ||
            data.newCase.customer === null || data.newCase.customer === undefined || data.newCase.customer === '' ||
            data.newCase.preSaleEquip === null || data.newCase.preSaleEquip === undefined || data.newCase.preSaleEquip === '') {
            e.preventDefault();
            e.stopPropagation();

        } else {
            updateData(data);
        }
        setValidated(true);
    }

    const updateData = async (data) => {

        let msgId = store.addNotification(defaultMessage);

        if (id === '0') {
            dispatch(await caseInsert(data));
            dispatch(await caseQuery());

            //let pInsert = new Promise(async (reslove) => {
            //    dispatch(caseInsert(data));
            //    reslove();
            //});
            //let plogQuery = new Promise(async (reslove) => {
            //    dispatch(caseLogQuery({ caseId: id }));
            //    reslove();
            //});
            //await Promise.all([pInsert, plogQuery]);

        } else {
            let id = data.newCase.caseId;
            dispatch(await caseUpdate(data));
            dispatch(await caseQuery());
            //let pUpdate = new Promise(async (reslove) => {
            //    dispatch(caseUpdate(data));
            //    reslove();
            //});
            //let plogQuery = new Promise(async (reslove) => {
            //    dispatch(caseLogQuery({ caseId: id }));
            //    reslove();
            //});

            //await Promise.all([pUpdate, plogQuery]);
            dispatch(caseQueryDepartment());
        }

        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        close();
        hist.push('/business');


        //const isCanSave = handleCaseInputFool(data);//防呆 必填欄位
        //if (isCanSave) {

        //} else {
        //    window.alert('只有【預估成交金額】和【案例備註】不是必填欄位!');
        //}
    }

    /** state保留(user表示切換客戶編輯時回到畫面會初始化) **/
    useEffect(() => {
        if (id !== '0') {
            dispatch(caseLogQuery({ caseId: id }));

        }
        let items_newCase = JSON.parse(sessionStorage.getItem('newCase'));
        let items_param = JSON.parse(sessionStorage.getItem('param'));
        let items_inputValue = JSON.parse(sessionStorage.getItem('inputValue'));
        if (items_newCase) {
            let _newCase = {
                ...items_newCase,
                estimatedDate: new Date(items_newCase.estimatedDate)
            };
            let _param = {
                ...items_param,
                visitedDate: new Date(items_param.visitedDate)
            };
            setNewCase(_newCase);
            setParam(_param);
            setInputValue(items_inputValue);
            sessionStorage.removeItem('newCase');
            sessionStorage.removeItem('param');
            sessionStorage.removeItem('inputValue');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCase, param, inputValue]);

    //變數會在 function 結束時「消失」，但 state 變數會被 React 保留起來
    const [lastDate, setlastDate] = useState()
    //Select 客戶資料 快速搜尋
    const defaultOptions = [{}];
    customers.forEach(item => {
        let tax = '';
        if (item.taxId != null) {
            tax = item.taxId;
        }
        defaultOptions.push({
            value: item.customerId,
            label: '(' + tax + ')' + item.name

        });

    });

    const contacts = customers.find(x => x.customerId === newCase.customer);

    const style = {
        padding: '0.1rem 2.25rem 0.1rem 0.75rem'
    };
    const BusinessEditBox = useSelector(x => x.ui.BusinessEditBox);


    return (
        <React.Fragment>
            <Offcanvas
                show={true}
                onHide={close}
                placement="end"
                style={{ minWidth: '375px', width: '100%' }}
            >
                <Offcanvas.Header className="bg-primary text-light">
                    <Offcanvas.Title>
                        <FontAwesomeIcon className='rounded me-2' icon={TitleBar[1]} />
                        {TitleBar[0]}
                    </Offcanvas.Title>
                    <Button onClick={close}>
                        <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                        關閉
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container style={{ maxWidth: '760px', width: '100%' }}>
                        <Row >
                            <Col md={6} className="mx-auto" style={{ margin: '0rem', padding: '0rem' }}>
                                <Card className="mb-1" style={{ width: '100%', minWidth: '312px' }}>
                                    <Card.Body style={{ padding: '0rem 0.5rem' }}>
                                        <Form autoComplete="false" noValidate validated={validated} >
                                            <Row >
                                                <Form.Group style={{ margin: '0', padding: '0' }} as={Row} className="mb-1">
                                                    <Form.Label>客戶資料 <span class="text-danger">*</span></Form.Label>
                                                    <Col sm="9">

                                                        {/* 快速搜尋 功能*/}
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isSearchable="true"
                                                            isDisabled={BusinessEditBox.disabled}
                                                            value={defaultOptions[defaultOptions.findIndex((e) => e.value === newCase.customer)]}
                                                            onChange={(newValue) => {
                                                                let val = newValue;
                                                                setNewCase({ ...newCase, customer: val.value });
                                                            }}
                                                            options={defaultOptions}
                                                        />
                                                    </Col>

                                                    <Col sm="1">
                                                        <Row className="mx=auto">
                                                            <Button variant="link" size="sm" onClick={toggleEdit}>
                                                                <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                            </Button>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="2">
                                                        <Row className="mx-auto">
                                                            <Button variant="primary" onClick={toggleAdd}>
                                                                <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                            </Button>

                                                        </Row>
                                                    </Col>

                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>聯絡窗口</Form.Label>
                                                    <Col>

                                                        <select className="form-select" required
                                                            defaultValue={newCase.custContactsID}
                                                            onChange={(e) => {
                                                                setNewCase({
                                                                    ...newCase,
                                                                    custContactsID: e.target.value
                                                                })

                                                            }}>
                                                            {
                                                                contacts === undefined &&
                                                                < option key="-1" value="-1">{"(尚未設定)"}</option>
                                                            }
                                                            {
                                                                contacts !== undefined && contacts.custContacts.length <= 0 &&
                                                                < option key="-1" value="-1">{"(尚未設定)"}</option>
                                                            }
                                                            {

                                                                contacts !== undefined && contacts.custContacts.length > 0 &&
                                                                contacts.custContacts.map((item, index) => {
                                                                    return (<option key={index} value={item.id} >{item.name} -- {item.title} ({item.cellphone} ) </option>);
                                                                })

                                                            }


                                                        </select>


                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-1">
                                                    <Form.Label>預定銷售設備<span class="text-danger">*</span></Form.Label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        isSearchable="true"
                                                        isMulti="true"
                                                        value={inputValue}
                                                        onChange={(e) => {

                                                            setInputValue(e);
                                                            let data = '';
                                                            e.forEach(item => {
                                                                if (item.value === 12) {
                                                                    data += item.label + ',';
                                                                } else {
                                                                    data += item.value + ',';
                                                                }


                                                            })
                                                            setNewCase({ ...newCase, preSaleEquip: data });
                                                        }}
                                                        options={loadOptions}
                                                    />

                                                </Form.Group>
                                                <Form.Group className="mb-1">
                                                    <Form.Label>
                                                        <FontAwesomeIcon className='rounded me-2' icon={faThList} />案例名稱
                                                    </Form.Label>
                                                    <Form.Control type="text" required
                                                        defaultValue={newCase.name}
                                                        onChange={(e) => {
                                                            setNewCase({
                                                                ...newCase,
                                                                name: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Col>
                                                    <ConfigSelection
                                                        title={'案例狀態'} required
                                                        disabled={BusinessEditBox.disabled}
                                                        settings={status}
                                                        defaultValue={newCase.status}
                                                        onChange={(e) => {
                                                            setNewCase({ ...newCase, status: e.target.value });
                                                        }} />
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-1">
                                                        <Form.Label>負責人員</Form.Label>
                                                        <select
                                                            className="form-select" style={style} required
                                                            disabled={BusinessEditBox.disabled}
                                                            value={newCase.assistingBy == null ? -1 : newCase.assistingBy}
                                                            onChange={(e) => {
                                                                let userID = e.target.value;
                                                                let departmentID = e.target.selectedOptions[0].getAttribute('deparment');

                                                                setNewCase({
                                                                    ...newCase,
                                                                    assistingBy: userID,
                                                                    departmentID: departmentID
                                                                });
                                                            }}
                                                        >
                                                            {organization.members.map(item => {
                                                                return (<option key={item.ID} value={item.ID} deparment={item.DepartmentID}>{item.Name}</option>)
                                                            })}
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>


                                            <Form.Group className="mb-1">
                                                <Form.Label>預估成交金額</Form.Label>
                                                <FormattedNumber
                                                    defaultValue={newCase.totalValue === 0 || null ? '' : newCase.totalValue}
                                                    onChange={(val) => {
                                                        let data = val === '' || null ? 0 : val;

                                                        if (data !== 0 && data.length > 3) {
                                                            data = data.replace(/,/g, '');;
                                                        }
                                                        setNewCase({
                                                            ...newCase,
                                                            totalValue: data
                                                        });
                                                    }} />
                                            </Form.Group>
                                            <Form.Group className="mb-1">
                                                <Form.Label>既有設備/項目</Form.Label>
                                                <Form.Control type="text"
                                                    defaultValue={newCase.equipment}
                                                    onChange={(e) => {
                                                        setNewCase({
                                                            ...newCase,
                                                            equipment: e.target.value
                                                        });
                                                    }}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-1">
                                                <Form.Label>案例備註</Form.Label>
                                                <Form.Control type="text" as="textarea" spellCheck="false" rows={1}
                                                    defaultValue={newCase.comment}
                                                    onChange={(e) => {
                                                        setNewCase({
                                                            ...newCase,
                                                            comment: e.target.value
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2" style={{ width: '100%', minWidth: '312px' }}>
                                    <Card.Header>
                                        追蹤進度
                                    </Card.Header>
                                    <Card.Body>
                                        <Form autoComplete="false" noValidate validated={validated} onSubmit={(e) => {
                                            handleSubmit(e, {
                                                newCase: newCase,
                                                ...param
                                            })
                                        }}>
                                            <Row>
                                                <Col >
                                                    <ConfigSelection title={"案例等級"} required
                                                        settings={level}
                                                        defaultValue={newCase.level}
                                                        onChange={(e) => {
                                                            setNewCase({
                                                                ...newCase,
                                                                level: e.target.value
                                                            });

                                                        }} />
                                                    <ConfigSelection title={"銷售進度"} required
                                                        settings={progress}
                                                        defaultValue={newCase.progress}
                                                        onChange={(e) => {
                                                            setNewCase({
                                                                ...newCase,
                                                                progress: e.target.value
                                                            });
                                                        }} />
                                                </Col>
                                                <Col className="col-6">
                                                    <ConfigSelection title={"把握度"} required
                                                        settings={degree}
                                                        defaultValue={newCase.degree}
                                                        onChange={(e) => {
                                                            if (e.target.value === '5') {
                                                                setlastDate(newCase.estimatedDate)
                                                                setNewCase({
                                                                    ...newCase,
                                                                    degree: e.target.value,
                                                                    estimatedDate: null
                                                                });

                                                            } else {
                                                                setNewCase({
                                                                    ...newCase,
                                                                    degree: e.target.value,
                                                                    estimatedDate: lastDate
                                                                });

                                                            }

                                                        }} />
                                                    <Form.Group className="mb-1">
                                                        <Form.Label>預估成交時間</Form.Label>
                                                        <DatePicker className="form-control" required
                                                            locale="zh-TW"
                                                            selected={
                                                                newCase.estimatedDate == null ? newCase.estimatedDate : new Date(newCase.estimatedDate)
                                                            }
                                                            onChange={(date) => {
                                                                setNewCase({
                                                                    ...newCase,
                                                                    estimatedDate: date
                                                                });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-1">
                                                <Form.Label>拜訪日期</Form.Label>
                                                <DatePicker className="form-control" required
                                                    locale="zh-TW"
                                                    selected={param.visitedDate}
                                                    onChange={(date) => {

                                                        setParam({
                                                            ...param,
                                                            visitedDate: date
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                            <ConfigSelection title={"工作類別"} required
                                                settings={jobclass}
                                                defaultValue={param.jobClass}
                                                onChange={(e) => {
                                                    setParam({ ...param, jobClass: e.target.value });
                                                }} />
                                            <Form.Group className="mb-1">
                                                <Form.Control type="text" as="textarea" rows={5} placeholder="說明最新案例處理進度 :" required
                                                    defaultValue={param.changeReason}
                                                    onChange={(e) => {
                                                        setParam({
                                                            ...param,
                                                            changeReason: e.target.value
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                            {!BusinessEditBox.disabled &&
                                                <Form.Group>
                                                    <Row className="mx-auto">
                                                        <Button
                                                            variant="primary"

                                                            type="submit"
                                                            onSubmit={(e) => {
                                                                handleSubmit(e, {
                                                                    newCase: newCase,
                                                                    ...param
                                                                })
                                                            }}

                                                        >
                                                            <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                            儲存
                                                        </Button>
                                                    </Row>
                                                </Form.Group>
                                            }
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {is_ed &&
                                <Col md={6} className="mx-auto">
                                    <Card style={{ width: '100%', minWidth: '380px' }}>
                                        <Card.Header>
                                            歷史追蹤進度
                                        </Card.Header>
                                        <Card.Body style={{ padding: '0.5rem' }} >
                                            {
                                                caseLogs.map((x, index) =>
                                                    <CaseLog key={x.caseLogId} {...x} />
                                                )
                                            }
                                        </Card.Body>
                                    </Card>

                                </Col>
                            }

                        </Row>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment >
    );
}
const CaseLog = (props) => {
    const { caseLogId, level, degree, progress, estimatedDate, visitedDate, jobClass, changeReason, updatedBy, updatedDate } = props;

    return (
        <Alert key={caseLogId} variant="secondary" style={{ width: '100%' }}>
            <div className="col-sm-12 col-md-12">
                <div className="form-text" style={{ textAlign: 'left' }}>
                    建立時間 : <MemberText value={updatedBy} />-{moment(updatedDate).format("yyyy-MM-DD(dd)")}
                </div>
                <div className="form-text">
                    拜訪日期 :
                    {
                        moment(visitedDate).format("yyyy-MM-DD(dd)")
                    }
                </div>
                <LevelClassText level_value={level} degree_value={degree} progress_value={progress} jobclass_value={jobClass} />
                <div className="form-text">{changeReason}</div>
                <div className="form-text">
                    成交時間 :
                    {
                        moment(estimatedDate).format("yyyy-MM-DD(dd)")
                    }
                </div>
            </div>
        </Alert>
    );
}

const MemberText = (props) => {
    const { value } = props;
    const { members } = useSelector(x => x.organization);
    const member = members.find(x => x.ID === value);
    const NAME = member === undefined ? '' : member.Name;
    return (
        <span className="form-text">
            {NAME}
        </span>
    )
}

const LevelClassText = (props) => {
    const { level_value, degree_value, progress_value, jobclass_value } = props;
    const { level, degree, progress, jobclass } = useSelector(x => x.config);
    const set_level = level.find(x => x.value === level_value);
    const set_degree = degree.find(x => x.value === degree_value);
    const set_progress = progress.find(x => x.value === progress_value);
    const set_jobclass = jobclass.find(x => x.value === jobclass_value);
    return (
        <div className="form-text">
            等級:{set_level.text} &emsp;把握度:{set_degree.text}&emsp;進度:{set_progress.text}&emsp;類別:{set_jobclass.text}
        </div>)
}

