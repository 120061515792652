import { Form, Row, Col, Collapse, Container, FormControl, InputGroup, Button, ToggleButtonGroup, ToggleButton, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { caseQuery } from '../../action/case/caseActions.js';
import { updateParam, clearParam } from '../../action/query/queryAction.js';
import { updateStructureChecked } from '../../action/organization/organizationActions.js';
import { store } from 'react-notifications-component';
import '../Maintain/BtnforPhone.css';
import { devicesIsMobile } from '../../useRWD.js';

const completeMessage = {
    id: "0",
    title: "完成",
    message: "搜尋完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1500,
        onScreen: true
    }
};

const loadingMessage = {
    id: "1",
    title: "搜尋中",
    message: "資料載入中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 10000,
        onScreen: true
    }
};
const emptyCust = {
    name: null,
    assistingBy: null,
    custContacts: [],
    taxId: null,
    telephone: null,
    officialWebsite: null,
    address: null,
    note: null,
    customer: null,
    flag: 1
};
export const AdvancedFiltering = (props) => {
    const reduxState = useSelector((state) => state);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { ui, queryParam, config, organization, customers } = reduxState;
    const keyword = useRef(null);
    const BusinessAddBox = useSelector(x => x.ui.BusinessAddBox);

    //客戶
    /* eslint-disable */
    const caseMode = () => {
        if (id === null || id === "" || id === 0) return true;
        return false;
    }
    const data = caseMode() ? emptyCust : Object.assign({}, customers.find(e => e.customerId == id));
    /* eslint-enable */

    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI

    /* eslint-disable  */
    useEffect(async () => {

        queryParam.keyword = caseMode() ? '' : data.name;
        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            if (data.customerId > 0 && data.customerId !== 'undefined') {
                await queryCase(queryParam);
            } else {
                await dispatch(caseQuery());
            }
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []);
    /* eslint-enable  */

    let visibleNodes = [];
    const searchVisibleNodes = (tree) => {

        if (tree !== null) {
            if (tree.length > 0) {
                tree.forEach(node => {
                    if (node.children !== null) {
                        node.children.forEach(val => {
                            if (val.children !== null) {
                                visibleNodes.push(val);
                            } else if (val.visible === true) {
                                visibleNodes.push(val);
                            }
                        })
                    }
                })
            }
        }
    }

    const handleCheckboxChange = (e, selectQueryParam, assignQueryParam) => {
        let param = selectQueryParam(queryParam);
        let checked = e.target.checked;
        let value = e.target.value;
        if (checked) {
            param = [...param, value];
        }
        else {
            param = param.filter(x => x !== value);
        }
        let res = assignQueryParam(param);
        dispatch(updateParam(res));
    };

    const handleTotalValue = (e, type) => {
        let value = e.target.value;
        let param = {};
        switch (type) {
            case 'min':
                param = { totalValueStart: value };
                break;
            case 'max':
                param = { totalValueEnd: value };
                break;
            default:
                param = {};
        }
        console.log(param);
        dispatch(updateParam(param));
    }

    const DropDownassistingBy = (data) => {
        searchVisibleNodes(data);
        return visibleNodes;
    }
    const handleDropDownChange = (currentNode, selectedNodes) => {
        let val = [];
        let LevelCode = [];
        selectedNodes.forEach(x => {
            organization.departments.forEach((item) => {
                if (item.ID === parseInt(x.value)) {
                    LevelCode.push(item.LevelCode);
                }
            });
        })
        LevelCode.forEach(x => {
            organization.departments.forEach((item) => {
                if (item.LevelCode.includes(x)) {
                    val.push(item.ID);
                }
            });
        });

        selectedNodes.forEach(x => {
            if (x.value.includes('se')) {
                val.push(x.value);
            }
        });
        console.log(val);
        if (val.length === 1) val.push('se00000');
        queryParam.assistingBy = val;

    }

    const queryCase = async (param) => {
        let loadingID = store.addNotification(loadingMessage);
        await dispatch(caseQuery(param));
        store.removeNotification(loadingID);

        //搜尋完成顯示訊息 -> 進階篩選 收起
        if (ui.SearchBar.isCollapse === true) {
            ui.SearchBar.isCollapse = false;
        }
        store.addNotification(completeMessage);
    };


    const clearQuery = () => {
        queryParam.assistingBy = [];
        dispatch(updateStructureChecked());
        dispatch(clearParam());
    };

    const handleDatePickerChange = (modify) => {
        let res = Object.assign({}, queryParam, modify);
        console.log(res);
        dispatch(updateParam(res));
    }

    let isMoble = devicesIsMobile();//判斷手機
    return (
        <Container fluid="mx-0">
            <Row className="mx-0">
                <InputGroup className="mb-3">
                    {!BusinessAddBox.disabled &&
                        <Link to="/business/BusinessBox/0">
                            <Button variant="primary" style={{ background: '#00A2FF' }} >
                                {!isMoble && <FontAwesomeIcon className='rounded' icon={faPlus} />}
                                新增
                            </Button>
                        </Link>
                    }
                    <FormControl
                        ref={keyword}
                        type="search"
                        placeholder="輸入關鍵字 : "
                        aria-label="Search"
                        size="lg"
                        className="keyword-bar"
                    />
                    <Button variant="primary" onClick={() => {
                        const param = {
                            ...queryParam,
                            keyword: keyword.current.value
                        }
                        queryCase(param);
                    }}>
                        {!isMoble && <FontAwesomeIcon className='rounded' icon={faSearch} />}
                        搜尋
                    </Button>

                    <Button variant="light" onClick={clearQuery}>
                        {!isMoble && <FontAwesomeIcon className='rounded' icon={faTrash} />}
                        清除
                    </Button>

                </InputGroup>
            </Row>
            <Row className="AdFiltering-cust-phone" className="mb-3">
                <Collapse in={ui.SearchBar.isCollapse}>
                    <Row >
                        <Row >
                            <Col sm={6} >
                                <Card >
                                    <Card.Body className="AdFiltering-card">
                                        <Col>
                                            狀態
                                        </Col>
                                        <Col>
                                            <ToggleButtonGroup type="checkbox" style={{ display: 'flex', flexWrap: 'wrap' }} >
                                                {config.status.map(item => {
                                                    let isChecked = queryParam.status.includes(item.value.toString());
                                                    return <ToggleButton variant="outline-primary" className="AdFiltering-btn" style={{ marginRight: '5px' }}
                                                        inline
                                                        key={item.configId}
                                                        id={item.configId}
                                                        value={item.value}
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(
                                                                e,
                                                                (param) => param.status,
                                                                (param) => ({ status: param }));
                                                        }}
                                                    >
                                                        {item.text + '' + item.comment}
                                                    </ToggleButton>
                                                })}
                                            </ToggleButtonGroup>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col >
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        <Col>
                                            把握度
                                        </Col>
                                        <Col>
                                            <ToggleButtonGroup type="checkbox" >
                                                {config.degree.map(item => {
                                                    let isChecked = queryParam.degree.includes(item.value.toString());
                                                    return <ToggleButton variant="outline-primary" className="AdFiltering-btn" style={{ marginRight: '5px' }}
                                                        inline
                                                        key={item.configId}
                                                        name={item.text}
                                                        id={item.configId}
                                                        value={item.value}
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(
                                                                e,
                                                                (param) => param.degree,
                                                                (param) => ({ degree: param }));
                                                        }}>
                                                        {item.text}
                                                    </ToggleButton>
                                                })}
                                            </ToggleButtonGroup>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col >
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        <Col>
                                            等級
                                        </Col>
                                        <Col>
                                            <ToggleButtonGroup type="checkbox" >
                                                {config.level.map(item => {
                                                    let isChecked = queryParam.level.includes(item.value.toString());
                                                    return <ToggleButton variant="outline-primary" className="AdFiltering-btn" style={{ marginRight: '5px' }}
                                                        inline
                                                        key={item.configId}
                                                        name={item.text}
                                                        id={item.configId}
                                                        value={item.value}
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(
                                                                e,
                                                                (param) => param.level,
                                                                (param) => ({ level: param }));
                                                        }}
                                                    >
                                                        {item.text + '' + item.comment}
                                                    </ToggleButton>
                                                })}
                                            </ToggleButtonGroup>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col sm={4} >
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        <Col>
                                            負責人員
                                        </Col>
                                        <Col>
                                            <DropdownTreeSelect className="mdl-demo"
                                                data={DropDownassistingBy(organization.structure)}
                                                onChange={handleDropDownChange}
                                            />
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={8}>
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        <Col >
                                            銷售進度
                                        </Col>
                                        <Col>
                                            <ToggleButtonGroup type="checkbox" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {config.progress.map(item => {
                                                    let isChecked = queryParam.progress.includes(item.value.toString());
                                                    return <ToggleButton variant="outline-primary" className="AdFiltering-btn" style={{ marginRight: '5px' }}
                                                        inline
                                                        key={item.configId}
                                                        name={item.text}
                                                        id={item.configId}
                                                        value={item.value}
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(
                                                                e,
                                                                (param) => param.progress,
                                                                (param) => ({ progress: param }));
                                                        }}
                                                    >
                                                        {item.text + '' + item.comment}
                                                    </ToggleButton>
                                                })}
                                            </ToggleButtonGroup>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        <Col>
                                            金額
                                        </Col>
                                        <Col>
                                            <Form autoComplete="false">
                                                <Form.Group>
                                                    <Row className="g-3">
                                                        <Col md={6}>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                placeholder="$ 最小值"
                                                                onChange={(e) => { handleTotalValue(e, 'min'); }}
                                                                value={queryParam.totalValueStart}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Control
                                                                size="sm"
                                                                type="text"
                                                                placeholder="$ 最大值"
                                                                onChange={(e) => { handleTotalValue(e, 'max'); }}
                                                                value={queryParam.totalValueEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form>
                                        </Col>


                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        最後更新時間
                                        <Form autoComplete="false">
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Row className="g-3">
                                                    <Col md={6}>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholder="開始"
                                                            selected={queryParam.updatedDateStart}
                                                            isClearable
                                                            selectsStart
                                                            dateFormat="yyyy/MM/dd"
                                                            startDate={new Date()}
                                                            endDate={queryParam.updatedDateEnd}
                                                            onChange={(date) => {
                                                                let modify = { updatedDateStart: date };
                                                                handleDatePickerChange(modify);
                                                            }} />
                                                    </Col>
                                                    <Col md={6}>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholder="結束"
                                                            isClearable
                                                            selectsEnd
                                                            dateFormat="yyyy/MM/dd"
                                                            startDate={queryParam.updatedDateStart}
                                                            selected={queryParam.updatedDateEnd}
                                                            onChange={(date) => {
                                                                let modify = { updatedDateEnd: date };
                                                                handleDatePickerChange(modify);
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <Card.Body className="AdFiltering-card">
                                        預估成交時間
                                        <Form autoComplete="false">
                                            <Form.Group>
                                                <Row className="g-3">
                                                    <Col md={6}>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholder="開始"
                                                            selected={queryParam.estimatedDateStart}
                                                            isClearable
                                                            selectsStart
                                                            dateFormat="yyyy/MM/dd"
                                                            startDate={new Date()}
                                                            endDate={queryParam.estimatedDateEnd}
                                                            onChange={(date) => {
                                                                let modify = { estimatedDateStart: date };
                                                                handleDatePickerChange(modify);
                                                            }} />
                                                    </Col>
                                                    <Col md={6}>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholder="結束"
                                                            isClearable
                                                            selectsEnd
                                                            dateFormat="yyyy/MM/dd"
                                                            startDate={queryParam.estimatedDateStart}

                                                            selected={queryParam.estimatedDateEnd}
                                                            onChange={(date) => {
                                                                let modify = { estimatedDateEnd: date };
                                                                handleDatePickerChange(modify);
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </Row>
                </Collapse>
            </Row>
        </Container>);
}



