import React, { useState, useEffect } from 'react';
import { Row, Button, Container, FormControl, InputGroup, Card, Collapse, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faChevronDown, faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { supplierQuery, supplierDelete } from '../../../../action/maintain/supplierAction.js';


import { useRWD, devicesIsMobile } from '../../../../useRWD.js';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import '../../../Maintain/BtnforPhone.css';
import moment from 'moment';

const queryParam = {
    keyword: null
};

const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const MachSupplier = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });

    const { supplier, organization } = useSelector(x => x);
    const { MachSupplierAdd, MachSupplierEdit, MachDelete } = useSelector(x => x.uiMach);

    let filteredData = supplier.supplier;

    const [data, setData] = useState(queryParam);
    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI

    useEffect(() => {
        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            await dispatch(supplierQuery());
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []) //  eslint-disable-line react-hooks/exhaustive-deps

    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 80) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (
        <React.Fragment>
            <Container fluid="mx-0">
                <Row className="mx-0">
                    <Row className="mx-0">
                        <InputGroup className="mb-3">
                            {!MachSupplierAdd.disabled &&
                                <Link to="/machSupplierEdit/0">
                                    <Button style={{ background: '#00A2FF' }} className="btn-custom-phone">
                                        <FontAwesomeIcon className='rounded me-2' icon={faPlus} />
                                        新增
                                    </Button>
                                </Link>
                            }
                            <FormControl
                                type="search"
                                placeholder="輸入關鍵字 : "
                                aria-label="Search"
                                size="1g"
                                className="keyword-bar"
                                defaultValue={queryParam.keyword}
                                onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                            />
                            <Button variant="primary" className="btn-custom-phone" onClick={() => {
                                dispatch(supplierQuery(data));
                                completeMessage.title = "搜尋";
                                store.addNotification(completeMessage);
                            }}>
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                        </InputGroup>
                    </Row>

                    <Row className="mx-0">
                        {isMoble &&
                            <MobileSearchUI />
                        }
                        {!isMoble &&
                            <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                <AgGridReact
                                    rowData={filteredData}
                                    rowStyle={rowStyle}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        wrapText: true,
                                        autoHeight: true,
                                        filter: true
                                    }}
                                    onGridReady={(params) => {
                                        setState({
                                            ...state,
                                            gridApi: params.api
                                        });
                                    }}
                                    paginationAutoPageSize={true}
                                    pagination={true}
                                >
                                    {!MachDelete.disabled &&
                                        <AgGridColumn headerName="" field="id" cellRendererFramework={DeleteRenderer} width={60} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                    <AgGridColumn headerName="廠商名稱" field="name" width={150}></AgGridColumn>
                                    {!MachSupplierEdit.disabled &&
                                        <AgGridColumn headerName="編輯" field="id" cellRendererFramework={EditRenderer} width={100} ></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="地址" field="address" width={200} ></AgGridColumn>
                                    <AgGridColumn headerName="聯繫方式" field="contactInfo" width={250} ></AgGridColumn>
                                    <AgGridColumn headerName="聯繫人" field="contactPerson" width={100} ></AgGridColumn>
                                    <AgGridColumn headerName="供應商評價" field="supplierEvaluation" width={120} ></AgGridColumn>
                                    <AgGridColumn headerName="備註" field="remark" width={300} ></AgGridColumn>
                                    <AgGridColumn headerName="更新者" field="updatedBy" width={100} valueGetter={(x) => {
                                        let item = organization.allMembers.find((e) => e.ID === x.data.updatedBy);
                                        return item === undefined ? null : item.Name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="更新時間" field="updatedDate" width={200} cellRenderer={(x) => {
                                        return x.data.updatedDate ? moment(x.data.updatedDate).format('YYYY-MM-DD') : ''; 
                                    }}></AgGridColumn>
                                </AgGridReact>
                            </div>
                        }
                    </Row>

                </Row>
            </Container>
        </React.Fragment>
    );

}

export const EditRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = () => {
        let id = cellValue;
        hist.push(`/machSupplierEdit/${id}`);


    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                編輯
            </button>
        </span>
    );
};

export const DeleteRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const dispatch = useDispatch();
    const { supplier } = useSelector(x => x);
    const DeleteMethod = async () => {
        let id = cellValue;
        const SupplierData = supplier.supplier.find(e => e.id == id);// eslint-disable-line eqeqeq

        await dispatch(supplierDelete(SupplierData));

    }

    return (
        <span>
            <button
                style={{ height: 25, width: 25, padding: 0 }}
                className="btn btn-danger"
                onClick={() => {
                    DeleteMethod();
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </span>
    );
};
export const MobileSearchUI = (props) => {

    const { supplier, organization } = useSelector(x => x);
    let filteredData = supplier.supplier;
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/machSupplierEdit/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    let _organization = organization.allMembers.find((e) => e.ID === data.updatedBy);
                    let _updatedDate = data.updatedDate ? moment(data.updatedDate).format('YYYY-MM-DD') : ''; 
                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>廠商名稱:{data.name}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            地址:{data.address}<br />
                                            聯繫方式:{data.contactInfo}<br />
                                            聯繫人:{data.contactPerson}&nbsp;
                                            供應商評價:{data.supplierEvaluation}<br />
                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.id}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.id);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.id}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    備註:{data.remark}<br />
                                    更新人員:{_organization.Name}&nbsp;
                                    更新時間:{_updatedDate}
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}