import React, { useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { Card, Button, Offcanvas, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useRWD } from '../../../../useRWD.js';
import { exportExcelMethod } from '../../../../exportExcel.js';
import moment from 'moment';

const defaultstate = {
    gridApi: null
};
export const MaintStaffReportDetail = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState(defaultstate);
    const close = () => {
        history.push(`/reportTab/employeeReport/0`);
    }

    const { maintStatus, maintJobClass, eqpType, eqpName } = useSelector(x => x.config);
    const { maintReport, organization, customers,machBasic } = useSelector(x => x);

    const data = maintReport.staffReportDetail.filter(x => x.employee === id);
    let item = organization.allMembers.find((e) => e.ID === id);
    const name = item.Name;

    //匯出excel
    const gridRef = useRef();
    const onBtnExport = useCallback((params) => {
        agGridDataHandle(gridRef, name, maintStatus, maintJobClass, eqpType, eqpName, customers, machBasic);
    }, []);//  eslint-disable-line react-hooks/exhaustive-deps
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 75) + 'px';

    return (

        <React.Fragment >
            <Offcanvas
                show={true}
                onHide={props.onHide}
                placement="end"
                style={{ minWidth: '375px', width: '100%' }}
            >
                <Offcanvas.Header className="bg-primary text-light">
                    <Offcanvas.Title>
                        <FontAwesomeIcon className='rounded me-2' icon={faSearch} />
                        {name}
                    </Offcanvas.Title>
                    <Button onClick={close}>
                        <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                        關閉
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container fluid>
                        <Button variant="outline-primary" className='me-1' onClick={() => { onBtnExport(); }}>
                            <FontAwesomeIcon className='rounded me-2' icon={faFileExport} />
                            Export
                        </Button>
                        <Card className="mb-1" style={{ width: '100%', minWidth: '312px' }}>
                            <Card.Body>
                                <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={data}
                                        rowStyle={rowStyle}
                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                        onGridReady={(params) => {
                                            setState({
                                                ...state,
                                                gridApi: params.api
                                            });
                                        }}
                                    >
                                        <AgGridColumn headerName="維修日期" field="date" width={120} cellRenderer={(x) => {
                                            return x.data.date ? moment(x.data.date).format('YYYY-MM-DD') : '';
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="客戶名稱" field="equipmentID" width={180} valueGetter={(x) => {
                                            let basicID = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                            let item = customers.find((e) => e.customerId === basicID.customerId);
                                            return item.name;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="機型" field="equipmentID" width={150} valueGetter={(x) => {
                                            let basicID = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                            let itemName = eqpName.find((e) => e.value === basicID.name);
                                            let item = eqpType.find((e) => itemName.configId === e.order && e.value === basicID.type);
                                            return item.text;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="設備序號" field="equipmentID" width={130} valueGetter={(x) => {
                                            let item = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                            return item.serialNo;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修內容分類" field="category" width={130} valueGetter={(x) => {
                                            let item = maintJobClass.find((e) => e.value === x.data.category);
                                            return item.text;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修內容說明" field="content" width={150} ></AgGridColumn>
                                        <AgGridColumn headerName="維修狀態說明" field="maintStatus" width={130} valueGetter={(x) => {
                                            let item = maintStatus.find((e) => e.value === x.data.maintStatus);
                                            return item.text;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修結果/建議" field="mainResults" width={150} ></AgGridColumn>
                                        <AgGridColumn headerName="車程(分)" field="transTime" width={110} ></AgGridColumn>
                                        <AgGridColumn headerName="工時(時)" field="workingHours" width={110} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Card.Body>
                        </Card>

                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>

    )
}
//匯出資料處理
const agGridDataHandle = (gridRef, employeeName, maintStatus, maintJobClass, eqpType, eqpName, customers, machBasic) => {
    let columns_array = [];
    let rows_array = [];
    //表標頭headerName
    const _columnApi = gridRef.current.api.columnModel.columnApi.getAllDisplayedColumns();
    _columnApi.forEach((item, index) => {
        const excelTitle = {
            name: '',
        };
       
        excelTitle.name = item.userProvidedColDef.headerName;
        columns_array.push(excelTitle);
    })
    //表資料
    gridRef.current.api.forEachNode((node, index) => {
        let basicID = machBasic.basic.find((e) => e.id === node.data.equipmentID);
        let _customers = customers.find((e) => e.customerId === basicID.customerId);
        
        let itemName = eqpName.find((e) => e.value === basicID.name);
        let _eqpType = eqpType.find((e) => itemName.configId === e.order && e.value === basicID.type);
        let _maintJobClass = maintJobClass.find((e) => e.value === node.data.category);
        let _maintStatus = maintStatus.find((e) => e.value === node.data.maintStatus);

        let temp = [];
        temp[0] = node.data.date ? moment(node.data.date).format('YYYY-MM-DD') : '';;
        temp[1] = _customers.name;
        temp[2] = _eqpType.text;
        temp[3] = basicID.serialNo;
        temp[4] = _maintJobClass.text;
        temp[5] = node.data.content;
        temp[6] = _maintStatus.text;
        temp[7] = node.data.mainResults;
        temp[8] = node.data.transTime;
        temp[9] = node.data.workingHours;
        rows_array.push(temp);
    });
    let Title = employeeName + '_工程師紀錄分析';
    //匯出Excel
    exportExcelMethod(columns_array, rows_array, Title);
}