import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { CenteredModalYesNo } from '../../../../components/Material/CenteredModal';
import { basicInsert, basicUpdate } from '../../../../action/maintain/basicAction.js';
import { apiMachBasicQuery } from '../../../../utils/api.js';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};

const queryParam = {
    keyword: null
};
const emptyBasic = {
    id: null,
    serialNo: '',
    name: '0',
    type: '0',
    status: '0',
    area: '0',
    site: '',
    supplierID: '0',
    purchaseContractID: '',
    customerId: null,
    remark: '',
    restockDate: null,
    deliveryDate: null
};


export const MachBasicEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { eqpName, eqpType, eqpStatus, eqpArea } = useSelector(x => x.config);
    const { customers, machBasic, machHandle } = useSelector(x => x);
    const { MachBasicSnoEdit } = useSelector(x => x.uiMach);
    const { id } = useParams();

    const [data, setData] = useState(emptyBasic);
    // eslint-disable-next-line no-unused-vars
    const [_EQPtype, setEQPtype] = useState([]);
    const [handleEdit, setHandleEdit] = useState({ disabled: true });
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        if (id !== '0') {
            //eslint-disable-next-line eqeqeq
            const MachBasicData = machBasic.basic.find(e => e.id == id);
            setData(MachBasicData);
            let itemName = eqpName.find((e) => e.value === MachBasicData.name);
            _EQPtype.length = 0;
            eqpType.forEach((temp) => {
                if (temp.order === parseInt(itemName.configId)) {
                    _EQPtype.push(temp);
                }
            })
            //eslint-disable-next-line eqeqeq
            let MachHandleData = machHandle.handle.find(e => e.equipmentID == id);
            if (MachHandleData !== undefined) {
                setHandleEdit({ ...handleEdit, disabled: false });
            }
            if (MachBasicSnoEdit.disabled === false) {
                setEdit(false);
            } else {
                setEdit(true);
            }
        }
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps

    const [custName, setCustName] = useState('');//eslint-disable-line no-unused-vars
    const insertOrUpdateData = async (data) => {
        //設備序號不重複
        queryParam.keyword = data.serialNo;
        let res = await apiMachBasicQuery(queryParam);
        let item = JSON.parse(res.request.response);

        let msgId = store.addNotification(defaultMessage);
        if (id === '0') {
            if (item.length <= 0) {
                handleInsertOrUpdate(dispatch(basicInsert(data)), msgId, completeMessage);
               
            } else {
                if (item.find((e) => e.serialNo === data.serialNo)) {
                    window.alert('手握把序號重複!!');
                } else {
                    handleInsertOrUpdate(dispatch(basicInsert(data)), msgId, completeMessage);
                }
                
            }

        } else {
            handleInsertOrUpdate(dispatch(basicUpdate(data)), msgId, completeMessage);
            
        }



    }
    const handleInsertOrUpdate = async (data, msgId, completeMessage) => {
        await data;
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        close();
    }
    const handleToggleAdd = () => {
        history.push(`/machHandleEdit/0`);
    }
    const handleToggleEdit = () => {
        //eslint-disable-next-line eqeqeq
        let MachHandleData = machHandle.handle.find(e => e.equipmentID == id);
        history.push(`/machHandleEdit/${MachHandleData.id}`);
    }
    //客戶新增編輯Btn
    const custToggleAdd = () => {

        history.push(`/customerbox/0`);
    }
    const custToggleEdit = () => {
        if (data.customerId === "" || data.customerId === null) {
            window.alert("請先選擇客戶");
            return;
        }

        history.push(`/customerbox/${data.customerId}`);
    }
    const close = () => {
        /*history.push(`/machBasicTab/machBasic/0`);*/
        history.goBack();
    }
    //Select 客戶資料 快速搜尋
    const defaultOptions = [{}];
    customers.forEach(item => {
        let tax = '';
        if (item.taxId != null) {
            tax = item.taxId;
        }
        defaultOptions.push({
            value: item.customerId,
            label: '(' + tax + ')' + item.name,
            name: item.name
        });

    });
    //欄位驗證-required
    const [validated, setValidated] = useState(false);
    //modal
    const [modalShow, setModalShow] = useState(false);

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Title>設備基本資料</Card.Title>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={(e) => {
                                    const from = e.currentTarget;
                                    e.preventDefault();//防止預設行為
                                    if (from.checkValidity() === false ||
                                        data.serialNo === '' || data.name === '0' || data.type === '0' || data.status === '0'
                                        || data.area === '0') {
                                        e.stopPropagation();
                                    } else {

                                        setModalShow(true);
                                    }
                                    setValidated(true);

                                }}>

                                    <Row style={{ margin: '0 0 20px 0' }} >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Form.Group style={{ margin: '0', padding: '0' }} as={Row} className="mb-1">
                                            <Form.Label>客戶資料 <span class="text-danger" style={{ fontSize: '14px', padding: 'revert' }}>   *必填</span></Form.Label>
                                            <Col sm="9">

                                                {/* 快速搜尋 功能*/}
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isSearchable="true"
                                                    value={defaultOptions[defaultOptions.findIndex((e) => e.value === data.customerId)]}
                                                    onChange={(newValue) => {
                                                        let val = newValue;
                                                        setCustName(val.name);
                                                        setData({ ...data, customerId: val.value });
                                                    }}
                                                    options={defaultOptions}
                                                />
                                            </Col>

                                            <Col sm="1">
                                                <Row className="mx=auto">
                                                    <Button variant="link" size="sm" onClick={custToggleEdit}>
                                                        <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                    </Button>
                                                </Row>
                                            </Col>
                                            <Col sm="2">
                                                <Row className="mx-auto">
                                                    <Button variant="primary" onClick={custToggleAdd}>
                                                        <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                    </Button>

                                                </Row>
                                            </Col>

                                        </Form.Group>
                                        <Row>
                                            <Col sm="3">
                                                <Form.Group className="mb-4" >
                                                    <Form.Label column >
                                                        <strong>區域</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            area: e.target.value
                                                        });
                                                    }}
                                                        value={data.area}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {eqpArea.map(item => {
                                                            return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>詳細地址</strong>
                                                    </Form.Label>

                                                    <Form.Control type="text" placeholder=""
                                                        defaultValue={data.site}
                                                        onChangeCapture={(e) => {
                                                            setData({ ...data, site: e.target.value });

                                                        }}
                                                    />

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>設備序號</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="" required disabled={isEdit}
                                                defaultValue={data.serialNo}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, serialNo: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>廠牌</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        _EQPtype.length = 0;
                                                        let selectedIndex = e.target.options.selectedIndex;
                                                        let EQPtype_temp = e.target.options[selectedIndex].getAttribute('data-key');
                                                        eqpType.forEach((temp) => {
                                                            if (temp.order === parseInt(EQPtype_temp)) {
                                                                _EQPtype.push(temp);
                                                            }
                                                        })
                                                        setData({
                                                            ...data,
                                                            name: e.target.value
                                                        });
                                                    }}
                                                        value={data.name}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {eqpName.map(item => {
                                                            return (<option key={item.configId} data-key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>


                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>機型</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            type: e.target.value
                                                        });
                                                    }}
                                                        value={data.type}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        { //eslint-disable-next-line array-callback-return
                                                            _EQPtype.map(item => {
                                                                return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                            })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>狀態</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            status: e.target.value
                                                        });
                                                    }}
                                                        value={data.status}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {eqpStatus.map(item => {
                                                            return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>進貨日期</strong>
                                                    </Form.Label>
                                                    <DatePicker className="form-control"

                                                        dateFormat="yyyy/MM/dd"
                                                        selected={
                                                            data.restockDate == null ? data.restockDate : new Date(data.restockDate)
                                                        }
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                restockDate: e
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>交機日期</strong>
                                                    </Form.Label>
                                                    <DatePicker className="form-control"

                                                        dateFormat="yyyy/MM/dd"
                                                        selected={
                                                            data.deliveryDate == null ? data.deliveryDate : new Date(data.deliveryDate)
                                                        }
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                deliveryDate: e
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        < Form.Group style={{ margin: '0', padding: '0' }} as={Row} className="mb-1">
                                            <Form.Label>手握把序號 <span class="text-danger" style={{ fontSize: '14px', padding: 'revert' }}>   *設備基本資料新增完成，才可新增手握把</span> </Form.Label>

                                            <Col sm="9">
                                                {//eslint-disable-next-line array-callback-return
                                                    machHandle.handle.map(item => {
                                                        if (item.equipmentID === parseInt(id)) {
                                                            return (<strong>{item.serialNo} ---【{item.numberOfShots}】<br /></strong>)
                                                        }
                                                    })}
                                            </Col>
                                            <Col sm="1">
                                                {!handleEdit.disabled &&
                                                    <Row className="mx=auto">
                                                        <Button variant="link" size="sm" onClick={handleToggleEdit}>
                                                            <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                        </Button>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col sm="2">
                                                {!handleEdit.disabled &&
                                                    <Row className="mx-auto">
                                                        <Button variant="primary" onClick={handleToggleAdd}>
                                                            <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                        </Button>

                                                    </Row>
                                                }
                                            </Col>

                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Label column >
                                                <strong>採購合約</strong>
                                            </Form.Label>

                                            <Form.Control type="text" placeholder=""
                                                defaultValue={data.purchaseContractID}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, purchaseContractID: e.target.value });

                                                }}
                                            />

                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Label column >
                                                <strong>備註</strong>
                                            </Form.Label>

                                            <Form.Control type="text" as="textarea"
                                                defaultValue={data.remark}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, remark: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>


                                </Form>
                                <CenteredModalYesNo
                                    title={'存檔'}
                                    message={'是否存檔?'}
                                    btnText1={'存檔'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { insertOrUpdateData(data); setModalShow(false); }}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>

    )
}




