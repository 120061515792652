import { store } from 'react-notifications-component';
import { apiUserLogin } from '../../utils/api.js';
import { initOrganizationStructure, updateStructureChecked } from '../organization/organizationActions.js';
import { customerQuery } from '../customer/customerActions.js';
import { defaultQueryParam } from '../case/caseActions.js';
import { initConfig } from '../config/configActions.js';
import { clearParam } from '../query/queryAction.js';
import { userGroupQuery, userFunctionQuery, AdminFuncQuery } from '../permission/adminActions.js';
import { productSettingsQuery } from '../material/productAction.js';
import { productTrackQuery } from '../material/productTrackAction.js';
import { salseSlipQuery } from '../material/qrCodeAction.js';
import { MaterialLogQuery } from '../material/meterialLogAction.js';
import { SalesSlipCustomerQuery } from '../material/statisticsReportAction.js';
import { supplierQuery } from '../maintain/supplierAction.js';
import { basicQuery } from '../maintain/basicAction.js';
import { handleQuery } from '../maintain/handleAction.js';
import { recordQuery } from '../maintain/recordAction.js';
import { contractQuery } from '../maintain/contractAction.js';
import {
    toggleBusiness, toggleBusinessEditBox, toggleBusinessAddBox,
    toggleCustomer, toggleCustomerAdd, toggleCustomerEdit, toggleBusinessReport, toggleAdministration,
    toggleMaterialSetQuery, toggleMaterialSetEdit, toggleMaterialQRCodeQuery, toggleMaterialQRCodeAdd, toggleMaterialTrackQuery
} from '../../action/ui/uiActions.js';
import {
    toggleMachSupplierQuery, toggleMachSupplierAdd, toggleMachSupplierEdit,
    toggleMachBasicQuery, toggleMachBasicAdd, toggleMachBasicEdit,
    toggleMachHandleQuery, toggleMachHandleAdd, toggleMachHandleEdit,
    toggleMachRecordQuery, toggleMachRecordAdd, toggleMachRecordEdit,
    toggleMachContractQuery, toggleMachContractAdd, toggleMachContractEdit,
    toggleMachReport, toggleMaintStaffReport,
    toggleMachBasicSnoEdit, toggleMachHandSnoEdit, toggleMachDelete
} from '../../action/ui/uiActionMach.js';


import Cookies from 'universal-cookie';

const failMessage = {
    title: "登入失敗",
    message: "帳號或密碼輸入錯誤，請重新確認後重試",
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss:
    {
        duration: 5000,
        onScreen: true
    }
};


export const doLogin = (req) => async (dispatch, getState) => {
    let resp = await apiUserLogin(req);;
    const user = JSON.parse(resp.request.response);
    console.log(user);

    if (resp.status !== 200 || user.Data === undefined) {
        dispatch(doLogout());
        return store.addNotification(failMessage);
    }

    const departmentID = user.Data.DepartmentID;
    dispatch(await setupSession(user.Data));
    dispatch(await initOrganizationStructure(departmentID));
    dispatch(await initConfig());
    //權限設定
    dispatch(await userGroupQuery());
    dispatch(await userFunctionQuery());
    const data = await dispatch(AdminFuncQuery(user.Data.UserId));//權限 UI設定

    uiSet(dispatch, data);

    dispatch(await customerQuery());//客戶資料

    defaultQueryParam.assistingBy.push(user.Data.UserId);
    //dispatch(await caseQuery(defaultQueryParam));//案例資料

    //物料管理
    dispatch(await salseSlipQuery());
    dispatch(await productSettingsQuery());
    dispatch(await productTrackQuery());
    dispatch(await MaterialLogQuery());
    dispatch(SalesSlipCustomerQuery());
    //維修管理
    dispatch(supplierQuery());
    dispatch(basicQuery());
    dispatch(handleQuery());
    dispatch(recordQuery());
    dispatch(contractQuery());

    dispatch(updateStructureChecked(true));




    dispatch(clearParam());
}


const typeLogout = 'SET_LOGOUT'
export const doLogout = () => {
    const cookies = new Cookies();
    cookies.remove('AuthToken', { expires: new Date(Date.now() - 1) });
    sessionStorage.clear();
    return {
        type: typeLogout
    };
}


export const authToken = 'authToken';
const typeSetupSession = 'setupSession';
const setupSession = (user) => {
    const cookies = new Cookies();
    cookies.set('AuthToken', user.AuthToken, { path: '/' });
    sessionStorage.setItem(authToken, user.AuthToken);

    return {
        type: typeSetupSession,
        user: {
            authToken: user.AuthToken,
            userName: user.FullNameInChinese,
            userID: user.UserId,
            departmentID: user.DepartmentID,
            isAuth: user.AuthToken ? true : false
        },
        isActive: false
    };
}
const uiSet = async (dispatch, data) => {

    dispatch(toggleBusiness(!data[0].enable));
    dispatch(toggleBusinessAddBox(!data[1].enable));
    dispatch(toggleBusinessEditBox(!data[2].enable));
    dispatch(toggleCustomer(!data[3].enable));
    dispatch(toggleCustomerAdd(!data[4].enable));
    dispatch(toggleCustomerEdit(!data[5].enable));
    dispatch(toggleBusinessReport(!data[6].enable));
    dispatch(toggleAdministration(!data[7].enable));
    dispatch(toggleMaterialSetQuery(!data[8].enable));
    dispatch(toggleMaterialSetEdit(!data[9].enable));
    dispatch(toggleMaterialQRCodeQuery(!data[10].enable));
    dispatch(toggleMaterialQRCodeAdd(!data[11].enable));
    dispatch(toggleMaterialTrackQuery(!data[12].enable));
    //維修管理 ui
    dispatch(toggleMachSupplierQuery(!data[13].enable));
    dispatch(toggleMachSupplierAdd(!data[14].enable));
    dispatch(toggleMachSupplierEdit(!data[15].enable));
    dispatch(toggleMachBasicQuery(!data[16].enable));
    dispatch(toggleMachBasicAdd(!data[17].enable));
    dispatch(toggleMachBasicEdit(!data[18].enable));
    dispatch(toggleMachHandleQuery(!data[19].enable));
    dispatch(toggleMachHandleAdd(!data[20].enable));
    dispatch(toggleMachHandleEdit(!data[21].enable));
    dispatch(toggleMachRecordQuery(!data[22].enable));
    dispatch(toggleMachRecordAdd(!data[23].enable));
    dispatch(toggleMachRecordEdit(!data[24].enable));
    dispatch(toggleMachContractQuery(!data[25].enable));
    dispatch(toggleMachContractAdd(!data[26].enable));
    dispatch(toggleMachContractEdit(!data[27].enable));
    dispatch(toggleMachReport(!data[28].enable));
    dispatch(toggleMaintStaffReport(!data[29].enable));
    dispatch(toggleMachBasicSnoEdit(!data[30].enable));
    dispatch(toggleMachHandSnoEdit(!data[31].enable));
    dispatch(toggleMachDelete(!data[32].enable));
}

const initState = {
    authToken: '',
    userName: '',
    userID: '',
    isAuth: false,
    departmentID: ''
}

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeSetupSession:
            return {
                ...action.user,
                isActive: action.isActive
            };

        case typeLogout:
            return initState;
        default:
    }
    return state;
};