import { userGroupInsert, userGroupUpdate, AdminFuncQuery } from '../../action/permission/adminActions.js';
import React, { useState, useEffect } from 'react';
import { Card, Row, Tab, Col, Button, Container, Form, Table, ListGroup } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import '../Permission/Admin.css';
import { useSelector, useDispatch } from 'react-redux';

import { requestDepartment, requestMembners } from '../../action/organization/organizationActions';
import { store } from 'react-notifications-component';


const completeMessage = {
    title: "完成",
    message: "新增/更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1500,
        onScreen: true
    }
};

//失敗跳窗
const RolefailMessage = {
    title: "失敗",
    message: "有資料未填寫",
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss:
    {
        duration: 5000,
        onScreen: true
    }
};

export const UserGroup = (props) => {

    const dispatch = useDispatch();

    const { administration, organization } = useSelector(x => x);
    const TKdeptInfo = [];
    organization.departments.forEach((item) => {
        if (item.LevelCode.includes('259')) {
            TKdeptInfo.push(item);
        }
    });
    const emptyAdmin = {
        id: null,
        roleID: null,
        userID: null,
        name: null
    };

    const [AdminData, setAdminData] = useState(emptyAdmin);

    useEffect(() => {

        handleUserGrid(AdminData.userID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //使用者
    const userInsertUpdate = async (data) => {
        let isExist = false;

        administration.userGroup.forEach((item) => {
            if (item.userID === data.userID) {
                isExist = true;
                data.id = item.id;
            }
        })
        //驗證是否有填寫名稱 沒填寫的話會跳錯誤提示窗
        if (data.name === null || data.userID === null) {
            store.addNotification(RolefailMessage);
        }
        else {
            //使用者存在 就走更新 
            if (isExist === true) {
                await dispatch(userGroupUpdate(data));
            } else {
                data.id = -1;
                await dispatch(userGroupInsert(data));
            }
            //新增/更新提示訊息
            store.addNotification(completeMessage);

        }
    }
    //使用者 CheckBox
    const [isUserSelected, setUserSelected] = useState(() => {
        let array = [];
        if (administration.allRoleid.length > 0) {
            administration.allRoleid.forEach((en) => {
                array.push(false);
            })

        }
        return array;
    });
    //使用者 
    const handleUserCheckboxChange = (e, _name) => {
        let selected = e.target.checked;
        let index = e.target.id === '' ? 0 : e.target.id;
        isUserSelected[index] = selected;
        if (selected && !AdminData.roleID.includes(e.target.value)) {
            AdminData.roleID += e.target.value + ',';
            AdminData.name = _name;
        } else if (!selected && AdminData.roleID.includes(e.target.value)) {
            let array = AdminData.roleID.split(',');
            let result = '';
            array.forEach((str) => {
                if (str !== e.target.value && str !== '') {
                    result += str + ','
                }
            })
            AdminData.roleID = result;
        }

        let array = [];
        isUserSelected.forEach((en) => {
            array.push(en);
        })
        isUserSelected.length = 0;
        setUserSelected(array);
    };
    //使用者 user
    const handleUserGrid = (e) => {

        let checkBox = [];
        let result = '';
        let roleid_str = null;
        let Name_str = null;
        administration.userGroup.forEach((en) => {
            if (en.userID === e) {
                roleid_str = en.roleID;
            }
        })


        administration.allRoleid.forEach((en) => {
            if (roleid_str !== null) {
                if (roleid_str.includes(en.roleID)) {
                    checkBox.push(true);
                    result += en.roleID + ','
                    Name_str = en.remark;
                } else {
                    checkBox.push(false);
                }
            } else {
                checkBox.push(false);
            }
        })

        let newData = {
            ...AdminData,
            roleID: result,
            name: Name_str,
            userID: e
        };
        setAdminData(newData);

        isUserSelected.length = 0;
        setUserSelected(checkBox);
    }
    //權限瀏覽表
    const [thisUserFunc, setThisUserFunc] = useState([]);// eslint-disable-line no-unused-vars
    const tableRowEvents = async (e) => {
        const data = await dispatch(AdminFuncQuery(e.target.value));
        let array = [];
        
        data.forEach((x) => {
            if (x.enable === true) {
                array.push(x.funNameTw);
            }
        });
        thisUserFunc.length = 0;
        setThisUserFunc(array);
    }

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Row className="mb-3 row-end">

                                    <Col sm="2">
                                        <DebugUserSelector onChange={(e) => {
                                            setAdminData({
                                                ...AdminData,
                                                userID: e.target.value
                                            });
                                            handleUserGrid(e.target.value);
                                        }} />
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="primary"
                                            onClick={(e) => {
                                                console.log(AdminData);
                                                userInsertUpdate(AdminData);
                                            }}
                                        >
                                            使用者存檔
                                        </Button>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm="4">
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>群組</th>
                                                    <th>Check</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    administration.allRoleid.map((item, index) => {
                                                        return (<tr key={index}>
                                                            <td>{item.remark}</td>
                                                            <td >
                                                                <Form.Check
                                                                    checked={isUserSelected.length === 0 ? false : isUserSelected[index]}
                                                                    id={index}
                                                                    key={index}
                                                                    value={item.roleID}
                                                                    onChange={(e) => {
                                                                        handleUserCheckboxChange(e, item.remark);
                                                                    }}
                                                                /></td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>

                                    <Col sm="5">
                                        <div style={{ borderCollapse: 'collapse', overflowY: 'scroll', maxHeight:'650px' }} >
                                            <Table striped bordered hover size="sm" >
                                                <thead>
                                                    <tr>
                                                        <th colSpan={3} style={{ background: '#00b395', textAlign:'center' }} >權限總覽表</th>
                                                    </tr>
                                                    <tr>
                                                        <th>使用者</th>
                                                        <th>權限</th>
                                                        <th>功能瀏覽</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { // eslint-disable-next-line array-callback-return
                                                        administration.userGroup.map((item, index) => {
                                                        if (!item.name.includes('資訊')) {
                                                            return (
                                                                <tr key={index} >
                                                                    <td>
                                                                        { // eslint-disable-next-line array-callback-return
                                                                            organization.members.map(x => {
                                                                            if (x.ID === item.userID) {
                                                                                return x.Name
                                                                            }
                                                                        })}

                                                                    </td>
                                                                    <td>
                                                                        { // eslint-disable-next-line array-callback-return
                                                                            administration.allRoleid.map(x => {
                                                                            if (item.roleID.includes(x.roleID)) {
                                                                                return x.remark + '、'
                                                                            }
                                                                        })}
                                                                    </td>
                                                                    <td>
                                                                        <Button variant="link" value={item.userID} onClick={(e) => { tableRowEvents(e) }} >功能</Button>
                                                                    </td>
                                                                </tr>)
                                                        }
                                                    })

                                                    }

                                                </tbody>
                                            </Table>

                                        </div>
                                  
                                    </Col>
                                    <Col >
                                        <ListGroup>
                                            <ListGroup.Item active>可使用功能清單</ListGroup.Item>
                                            {
                                                thisUserFunc.map((item) => (

                                                    <ListGroup.Item>{item}</ListGroup.Item>
                                                ))
                                            }
                                        </ListGroup>
                                    </Col>
                                </Row>
                                
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );

}

const DebugUserSelector = (props) => {
    const { onChange } = props;
    const dispatch = useDispatch();
    useEffect(() => {

        const param = {
            ID: 30,
            Code: '9'
        };
        dispatch(requestMembners(param));//工號
        dispatch(requestDepartment());//部門
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { members, departments } = useSelector(x => x.organization);
    return (
        <div>
            <Form.Label>使用者</Form.Label>
            <select className="form-select" onChange={onChange}>
                <option value="-1">請選擇</option>
                {
                    members.map(x => {
                        const department = departments.find(y => y.ID === x.DepartmentID);
                        return (<option key={x.ID} value={x.ID}>{x.Name} ({department.Name})  </option>)
                    })
                }
            </select>
        </div>
    )
}

