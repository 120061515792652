import React, { useState } from 'react';
import { Row,Tab, Button, Container, Card, Collapse, Col } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFilter, faFileExport, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AdvancedFiltering } from './AdvancedFiltering.jsx';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useGuestContact } from '../../utils/hooks';
import { toggleSearchBar } from '../../action/ui/uiActions.js';

import { useRWD, devicesIsMobile } from '../../useRWD.js';
import '../Maintain/BtnforPhone.css';

export const Business = (props) => {

    const hist = useHistory();
    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const { ShowNarBar = true } = props;
    const { allMembers } = useSelector(x => x.organization);
    const { jobclass, preSaleEquip } = useSelector(x => x.config);
    const [state, setState] = useState({ gridApi: null });
    const useQuery = useSelector(x => x.businessCase.case);
    const handlecustContact = useGuestContact(useQuery);
 

    const handleUser = (userID) => {
        let res = allMembers.find(x => x.ID == userID)// eslint-disable-line eqeqeq
        res = res ? res.Name : null;
        return res;
    }
    const handlejobClass = (x) => {
        let job = x.data.jobClass === undefined ? 1 : x.data.jobClass;
        if (job === -1) job = 1;
        let jobclassdata = jobclass.find((e) => e.value === job);
        return jobclassdata.text;
    }
    const handlePreSaleEquip = (x) => {
        let preSaleEquipdata = '';
        if (x.data.preSaleEquip === null || !x.data.preSaleEquip.includes(',') || x.data.preSaleEquip === undefined) {
            preSaleEquipdata = x.data.preSaleEquip;
        } else {

            let _preSaleEquip = x.data.preSaleEquip.split(',');
            for (let i = 0; i < (_preSaleEquip.length - 1); i++) {
                let preSaleEquip_text = preSaleEquip.find((e) => e.value === parseInt(_preSaleEquip[i]));

                if (i === (_preSaleEquip.length - 2) && preSaleEquip_text !== undefined) {
                    preSaleEquipdata += preSaleEquip_text.text;
                } else if (preSaleEquip_text === undefined) {
                    preSaleEquipdata += _preSaleEquip[i] + ',';
                }
                else {
                    preSaleEquipdata += preSaleEquip_text.text + ',';

                }
            }
        }
        return preSaleEquipdata;
    }

    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (

        <React.Fragment >
            {
                ShowNarBar &&
                <NavBar />
            }
            <Container fluid >
                <Row >
                    <AdvancedFiltering />
                </Row>

                <Row className="mx-auto">
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px', borderRadius: '10px' }}>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                
                                <Row className="align-items-center">
                                    
                                    <Col>

                                        <Button variant="secondary"  onClick={() => { dispatch(toggleSearchBar()); }}>
                                            <FontAwesomeIcon className='rounded' icon={faFilter} />
                                            進階篩選
                                        </Button>

                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="outline-primary"  onClickCapture={() => { state.gridApi.exportDataAsCsv(); }}>
                                            <FontAwesomeIcon className='rounded' icon={faFileExport} />
                                            Export
                                        </Button>
                                    </Col>
                                </Row>
                               
                                <Row>
                                    {isMoble &&
                                        <MobileSearchUI />
                                    }
                                    {!isMoble &&
                                        <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                            <AgGridReact
                                                rowData={useQuery}
                                                rowStyle={rowStyle}
                                                defaultColDef={{
                                                    sortable: true,
                                                    resizable: true,
                                                    wrapText: true,
                                                    autoHeight: true,
                                                    filter: true
                                                }}
                                                onGridReady={(params) => {
                                                    setState({
                                                        ...state,
                                                        gridApi: params.api
                                                    });
                                                }}
                                                onRowClicked={(e) => {
                                                    let id = e.data.caseId;
                                                    hist.push(`/business/BusinessBox/${id}`);
                                                }}

                                                paginationAutoPageSize={true}
                                                pagination={true}
                                            >
                                                <AgGridColumn headerName="客戶名稱" field="customerName" pinned="left"></AgGridColumn>
                                                <AgGridColumn headerName="案例名稱" field="name" pinned="left" width={200}></AgGridColumn>
                                                <AgGridColumn headerName="業務人員" valueGetter={(x) => {
                                                    let _member = handleUser(x.data.assistingBy);
                                                    let memberName = _member ? _member.Name : null;
                                                    return memberName;
                                                }} width={105}></AgGridColumn>
                                                <AgGridColumn headerName="拜訪日期" filterParams="DateFilterParams" cellRenderer={(x) => {
                                                    return x.data.visitedDate ? (new Date(x.data.visitedDate)).toLocaleDateString() : '';
                                                }} width={105}></AgGridColumn>
                                                <AgGridColumn headerName="工作類別" valueGetter={(x) => {
                                                    return handlejobClass(x);
                                                }} width={180}></AgGridColumn>
                                                <AgGridColumn headerName="聯絡窗口" valueGetter={(x) => {
                                                    let member = handlecustContact[(x.data.caseNo - 1)];
                                                    return member;
                                                }} width={150} ></AgGridColumn>
                                                <AgGridColumn headerName="處理進度" field="progressName" width={105}></AgGridColumn>
                                                <AgGridColumn headerName="診所既有設備" field="equipment" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="預定銷售設備" valueGetter={(x) => {
                                                    return handlePreSaleEquip(x);
                                                }} width={130}></AgGridColumn>
                                            </AgGridReact>


                                        </div>
                                    }


                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>



        </React.Fragment >
    );
}
export const MobileSearchUI = (props) => {
    const { allMembers } = useSelector(x => x.organization);
    const { jobclass, preSaleEquip } = useSelector(x => x.config);
    let filteredData = useSelector(x => x.businessCase.case);
    const handlecustContact = useGuestContact(filteredData);
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/business/BusinessBox/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    let _member = allMembers.find(x => x.ID == data.assistingBy);// eslint-disable-line eqeqeq
                    let memberName = _member ? _member.Name : null;
                    let _visitedDate = data.visitedDate ? (new Date(data.visitedDate)).toLocaleDateString() : '';
                    let _job = data.jobClass === undefined ? 1 : data.jobClass;
                    if (_job === -1) _job = 1;
                    let _jobclassdata = jobclass.find((e) => e.value === _job);

                    let _guestContact = handlecustContact[(data.caseNo - 1)];
                    let preSaleEquipdata = '';
                    if (data.preSaleEquip === null || !data.preSaleEquip.includes(',') || data.preSaleEquip === undefined) {
                        preSaleEquipdata = data.preSaleEquip;
                    } else {

                        let _preSaleEquip = data.preSaleEquip.split(',');
                        for (let i = 0; i < (_preSaleEquip.length - 1); i++) {
                            let preSaleEquip_text = preSaleEquip.find((e) => e.value === parseInt(_preSaleEquip[i]));

                            if (i === (_preSaleEquip.length - 2) && preSaleEquip_text !== undefined) {
                                preSaleEquipdata += preSaleEquip_text.text;
                            } else if (preSaleEquip_text === undefined) {
                                preSaleEquipdata += _preSaleEquip[i] + ',';
                            }
                            else {
                                preSaleEquipdata += preSaleEquip_text.text + ',';

                            }
                        }
                    }

                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>案例名稱:{data.name}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            客戶名稱:{data.customerName}<br />
                                            業務人員:{memberName}&nbsp;
                                            拜訪日期:{_visitedDate}<br />
                                            工作類別:{_jobclassdata.text}&nbsp;
                                            處理進度:{data.progressName}<br />
                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.caseId)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.caseId}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.caseId);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.caseId}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    診所既有設備:{data.equipment}<br />
                                    預定銷售設備:{preSaleEquipdata}<br />
                                    聯絡窗口:{_guestContact}<br />
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}